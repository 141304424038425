import { Injectable } from '@angular/core';
import { Observable, observable, Observer } from 'rxjs';
import { constant } from '../constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  url: string = constant.url;
  options = { headers: new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization' : sessionStorage.getItem('authorization')
      }) 
  };

  constructor(private _http: HttpClient) { }

  //subscribe
  subscribe(data) : Observable<any> {

let authToken = sessionStorage.getItem('authorization')

let options = { 'headers': new HttpHeaders({ 'Content-Type': 'application/json',
'Authorization': authToken
}) 
}
    let subscribeUrl: string = this.url + 'user/subscribe-plan'
    return this._http.post<boolean>(subscribeUrl,data, options);
  }

}