import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from '../forgot-password/forgot-password.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private _service: ForgotPasswordService, private toastr: ToastrService, private _router: Router, public router: Router) { 
    if (sessionStorage.getItem('authorization')) { 
      // determine if the user is logged in from this method , if not, redirect to previous screen
      window.history.go(-1);
  }
  }

  email: string;

  ngOnInit() {
  }

  //submit email Id if forgot password
  forgotPwd(){

      let data : any = {
          "email": this.email.trim()
      }

      this._service.forgotPwd(data).subscribe(x => {
        this.email = null;
        this.toastr.success(x.message, 'Suksess');
        this._router.navigate(['/login']);
      },
        err => {
          this.toastr.error('Feilmelding', err.error.message);
          // if(err.status == 422) {
          // }
        }
      );
  }

  //remove space 
  removeSpace(event) {

      var k = event ? event.which : event.keyCode;
      if (k == 32) return false;
  }

   //redirect to login page clicking login link
   redirectToLogin() {

    this.router.navigateByUrl('/login');
   }
}
