export enum constant{

    //local
    // url = 'http://192.168.168.14:3000/', // local

    //staging
    url = 'https://stagingapi.madetogrow.no/', //staging
    stripKey = 'pk_test_zbuZVxZ43gJUSaBRcRH7rKQY00qsXkziTr', // staging

    //live
    // url = "https://api.madetogrow.no/", //live
    // stripKey = 'pk_live_3omP9J4qAaqLb6WhMohzXgnt00XUyM6nBW' // live
}
