import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var swal: any;
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'madetogrow';

  constructor(public router: Router, private activeRoute: ActivatedRoute, private locationStrategy: LocationStrategy, private toastr: ToastrService) {
  
    // this.router.events.subscribe(event => {
    //   if(event instanceof NavigationEnd) {   
    //   }
    //   });

      window.addEventListener('beforeunload', function (e) {
        // e = e || window.event;

        // e.preventDefault();
        // // localStorage.removeItem('authorization');
        // e.returnValue = '';

    });

}

  ngOnInit() {
  } 
}
