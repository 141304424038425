import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SignUpService } from '../sign-up/sign-up.service';
import { Router } from '@angular/router';
// import { TranslateService } from 'ngx-toastr-with-translate/ngx-toastr-with-translate';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
declare var swal: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  constructor(private service: SignUpService, private toastr: ToastrService, public router: Router) { 
      
    if (sessionStorage.getItem('authorization')) { 
      
      // determine if the user is logged in from this method, else redirect to previous screen
        window.history.go(-1);
    }

    //reload the document to reinitiate the calendar plugin
    if(!localStorage.getItem('tempVar')) {
        localStorage.setItem('tempVar', '2');
    }else {
        localStorage.removeItem('tempVar');
        location.reload();
    }
}
  name: string;
  gender: any = 0;
  year: any;
  email: string;
  pwd: string;
  confirmPwd: string;
  ConfirmPwd: string;
  model: any;
  userRoles: any[] =[];
  userRole: number = 0;
  
  ngOnInit() {
    // $(document).ready(function(){
    //   $('.datepicker').datepicker({ 
    //     startDate: '-3d'
    // });
    // })


    
    this.getUserRoles();
    // $()
  }

  initializeYear(year1) {

      // var $j = $.noConflict();
      // $j("#datetimepicker").datepicker().datepicker('setDate', new Date());
      // $('#datetimepicker').datepicker();
    //   $('.datepicker').datepicker({ 
    //     startDate: '-3d'
    // }); 
  
   
    // $('#datetimepicker').datetimepicker(); 

    // $("#datetimepicker").datepicker().datepicker('setDate', new Date());
    

    //   $('#datetimepicker').datepicker({
    //     // uiLibrary: 'bootstrap'
    // });

    // $(document).ready(function() {
      // reset datepickers to today's date
      // $("#datetimepicker").datepicker('setDate', new Date());
      
      // });
     
    
  }
  //SIgn Up for new user
  signUp(year11){
let tempYear = $('#txtYear').val() //document.getElementById('txtYear');


// let a1 = new Date(a)
// let b = a.getYear();
if(!tempYear) {
  tempYear = 0;
}

if(this.gender == 0) {
  this.gender = "";
}

      let data : any = {
          "name": this.name.trim(),
          "gender": this.gender,
          "year_of_birth": Number(tempYear),
          "email": this.email.trim(),
          "password": this.pwd.trim(),
          "account_id": 0,
          "role_id": Number(this.userRole)
      }

      this.service.signUp(data).subscribe(x => {
        //after successful signup check security code in email Id and redirect page to login
        swal({
          // html: '<div class="m-bottom-10 font-25 swal2-modal h2">En link for å bekrefte kontoen din er sendt til din e-postadresse. </div> <div class="m-bottom-10 font-25 swal2-modal h2">NB! Sjekk evt. spam-mappe.</div>',
          title:  `<div style="font-size: 25px !important">En link for å bekrefte kontoen din er sendt til din e-postadresse. </div> <div style="font-size: 25px !important; margin-top: 10px">NB! Sjekk evt. spam-mappe.</div>`,
          type: "success",
          showLoaderOnConfirm: true,
          confirmButtonColor: '#3085d6',
        })

        this.name = null;
        this.year = null;
        this.email = null;
        this.pwd = null;
        this.confirmPwd = null;
        this.gender = 0;
        this.userRole = 0;

        // this.loading = false;
        setTimeout(()=> {
         
            //after successful signup redirect page to login
            this.router.navigate(['/login']);
        },2000);
        // this.toastr.success('Suksess', 'User Created');
        // this.toastr.success('Kontobekreftelseslink er sendt til kontoen din', 'Suksess',);
        this.name = null;
        this.year = null;
        this.email = null;
        this.pwd = null;
        this.confirmPwd = null;
        this.gender = 0;
        this.userRole = 0;
      },
        err => {

            if(err.error.status == '409') {
              this.toastr.error(err.error.message, 'Beskjed');
            } else {
              this.toastr.error('Feilmelding', err.error.message);
            }
            this.gender = 0;
            this.userRole = 0;
            // if(err.status == 422) {
            //   console.log(err);
            // }
          }
      );
  }


  getYear(year: HTMLInputElement) {
  }
  //remove space 
  removeSpace(event) {

        var k = event ? event.which : event.keyCode;
        if (k == 32) return false;
  }

  //get user roles
  getUserRoles() {

    this.service.getUserRoles().subscribe(x => {
        this.userRoles = x.data.rolesList;
    },
      err => {

          this.toastr.error('Feilmelding', err.error.message);
        }
    );
  }

  //navigae to terms and conditions page 
  navigateToTerms() {

    this.gender =0; 
    this.userRole = 0;
    this.router.navigate([]).then(result => { 
        // window.open('/terms-conditions', '_blank')
    });

    this.gender =0; 
    this.userRole = 0; 
   }

   //redirect to login page clicking login link
   redirectToLogin() {

    this.router.navigateByUrl('/login');
   }
}
