import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var swal: any;

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {
  vm = this;
  constructor(public router: Router, private activeRoute: ActivatedRoute, private locationStrategy: LocationStrategy, private toastr: ToastrService
    ) {

    // if user doesnt have subscription, and is loggedin, redirect to previous screen
    if(!sessionStorage.getItem('subscribed') && sessionStorage.getItem('authorization')) {
        window.history.go(-1);
    }

    if (sessionStorage.getItem('authorization')) { 

      // determine if the user is logged in from this method, if not, redirect to previous screen
      // sessionStorage.setItem('authorization', null);
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
        history.pushState(null, null, location.href);
      });
    }
    
    //if user not authorized, redirect to login page
    if (!sessionStorage.getItem('authorization')) {
      
        this.router.navigate(['login']);
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(this.router.url == '/login' && sessionStorage.getItem('authorization')) {
        return false;
    } else {
        return true;
    }
  }
  ngOnInit() {
  }

  logout() {
      let a = 1;

      swal({
        title: 'Er du sikker på at du vil logge ut?',
        type: "info",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja',
        cancelButtonText: "Nei" 
      } ).then(()=> {
        a=2
        // swal(
        //   'logget ut',
        //   'greit',
        //   'suksess'
        // )
        sessionStorage.removeItem('authorization');
        sessionStorage.removeItem('subscribed');
        this.router.navigate(['login']);

      });
  }

}
