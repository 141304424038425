import { Component, OnInit, NgZone } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd , CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
declare var swal: any;

import {  CreditCardService } from '../credit-card/credit-card.service';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit {
  routeParams: any;

  constructor(private _service: CreditCardService, private stripeService: StripeService, private toastr: ToastrService, public router: Router, private activeRoute: ActivatedRoute, private zone: NgZone) { 

    //if user not authorized, redirect to login page
    if (!sessionStorage.getItem('authorization')) {

        this.router.navigate(['login']);
    }

    //if user has subscription and is loggedin , send to stores page
    if(sessionStorage.getItem('subscribed') == 'true' && sessionStorage.getItem('authorization')) {
        this.router.navigate(['/stores']);
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // if user enters login in window link , but is authorized, stop from redirection, else redirect to login
    if(this.router.url == '/login' && sessionStorage.getItem('authorization') != null) {
        return false;
    } else {
        return true;
    }
  }
  name: string = '';
  cardName: string = '';
  cardNumber: number = null;
  cvv: number = null;
  expiry: any;
  stripDetails:any;
  elements: Elements;
  card: StripeElement;
  token:string = '';
  userId: string = null;
  membershipId: string = null;
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'no'
  };
  public loading: boolean = false;
  submitClicked: boolean = false;

  ngOnInit() {

      //initiate and mount stripe elements
      this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                iconColor: 'dark blue',//'#666EE8',
                color: 'black', // '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: 'grey'//'#b7cbe2',
                }
              }
            }
            
          });
          this.card.mount('#card-element');
        // this.buy();

        }
    });
  }

  ngAfterViewInit(){
		
		let a = this.router.url.split("/");		
		let changeUrl = a[1];
		if(changeUrl == 'credit-card'){
				this.routeParams = this.activeRoute.snapshot.params;
        this.userId = this.routeParams.userId;
        this.membershipId = this.routeParams.membershipId;
    }

  }

  subscription() {
    this.buy().then(succ => {
    });
  }
  // Method to create token in strip
  buy() {

    this.cardName = this.name;
    this.loading = true;
    const promise = new Promise((result, error) => {
      this.stripeService
      .createToken(this.card, {})
      .subscribe(token => {
        if(token.error) {
          this.name = this.cardName;
          this.toastr.error('Feilmelding', token.error.message);
          this.loading = false;
        }else if (token.token) {
          this.stripDetails = token.token.id;
          this.subscribe();
        } 
      });
    });
    return promise;   
  }

  
  subscribe(){

    let data : any = {
        "userId": this.userId,
        "membershipId": this.membershipId,
        "cardToken": this.stripDetails
    }

    this._service.subscribe(data).subscribe(x => {
      let serverResponce:any = x;
      this.toastr.success('Plan Subscribed', 'Suksess');
      this.loading = false;
      sessionStorage.setItem('subscribed', 'true');
      this.router.navigate(['/stores']);
    },
      err => {
        this.toastr.error('Feilmelding', err.error.message);
        this.loading = false;
      }
    );
}

  //remove space 
  removeSpace(event) {

      var k = event ? event.which : event.keyCode;
      if (k == 32) return false;
  }

  
  //logout
  logout() {
    let a = 1;

    swal({
      title: 'Er du sikker på at du vil logge ut?',
      type: "info",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: "Nei" 
    } ).then(()=> {
      // swal(
      //   'logget ut',
      //   'greit',
      //   'suksess'
      sessionStorage.removeItem('authorization');
      sessionStorage.removeItem('subscribed');
      this.router.navigate(['login']);
    });
}

//navigae to terms and conditions page 
  navigateToTerms() {

    this.router.navigate([]).then(result => { 
        // window.open('/terms-conditions', '_blank')
    });
  }
}
